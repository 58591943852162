import "../sass/style.scss";

window.addEventListener("load", () => {
  const banner = document.querySelector("body.node-type-group .block-field-node-field-banner");
  const profTitle = document.querySelector("body.node-type-group .block-system-title");
  if(banner && profTitle){
    alignCenter(banner, profTitle);
    profTitle.style.opacity = 1;
  }
    const mainItems = document.querySelector(".block-system-main-menu > .block-content");
    if (window.outerWidth < 767) {
      if(mainItems){
        mainItems.classList.add("su-main-nav");
      }
    }

    window.addEventListener("resize", () => {
        if (window.outerWidth < 767) {
          if(mainItems){
            mainItems.classList.add("su-main-nav");
          }
        } else {
          if(mainItems) {
            mainItems.classList.remove("su-main-nav");
          }
        }
        if(banner && profTitle){
          alignCenter(banner, profTitle);
        }
    });

    const secItems = document.querySelectorAll(".su-secondary-nav__menu li a");
    secItems.forEach((item) => {
        item.classList.add("su-secondary-nav__link");
    });

    //
    // Front content text overlay
    //
  const heroContent = document.querySelector(".block-hero>.block-content");
  const overlay = document.createElement('div');
  overlay.classList.add("overlay");
  if(heroContent && overlay) {
    heroContent.parentNode.appendChild(overlay);
    overlay.appendChild(heroContent);
  }

  //
  // Profile page overlay
  //
  const profileBanner = document.querySelector("body.node-type-group .block-field-node-field-banner");
  const profileTitle = document.querySelector("body.node-type-group .block-system-title");
  if(profileBanner !== null && profileTitle !== null) {
    profileTitle.classList.add("with-banner");
  }

  //
  // Rollover on endnotes
  //
  tabUpdate();

  function tabUpdate() {
    var endnotes = document.querySelectorAll("body.node-type-group .horizontal-tabs-pane a[id*='_ednref']");
    for (let i = 0; i < endnotes.length; i++) {
      endnotes[i].classList.add('lnk');
      const para = document.createElement('div');
      para.classList.add('popup');
      const footnote = document.querySelector(endnotes[i].hash);
      if (footnote !== null){
        let noteContent = footnote.parentElement;
        let noteContentCopy = noteContent.cloneNode(true);
        para.appendChild(noteContentCopy);
        endnotes[i].insertAdjacentHTML("afterend", para.outerHTML);
      }
      var popups = document.querySelectorAll("div.popup");
      endnotes[i].addEventListener("mouseenter",(e) => {
        popupClose();
        e.target.nextSibling.classList.add("show");

      });

    }
    document.addEventListener("click", popupClose);
    const horizTabs = document.querySelector("ul.horizontal-tabs-list li a");
    if (horizTabs !== null) {
      horizTabs.addEventListener("click",popupClose);
    }

    function popupClose(){
      [...popups].map((popup) => {
        let popupClasses = popup.classList;
        if(popupClasses.contains("show")){
          popupClasses.remove("show");
        }
      });
    }
  }
  //
  // Sticky horizontal tabs
  //
  const tabsel = document.querySelector("ul.horizontal-tabs-list")
  document.addEventListener("scroll", () => {
    if(tabsel){
      if (tabsel.getBoundingClientRect().top <= 0) {
        tabsel.classList.add("sticky");
      } else {
        tabsel.classList.remove("sticky");
      }
    }
  });

//
// switch horizontal tabs according to url hash
//
  const horTabs = document.querySelectorAll("article.node-group .horizontal-tabs-list li");
  const horTabPanes = document.querySelectorAll("article.node-group .horizontal-tabs-panes .horizontal-tabs-pane");
  const categories = document.querySelectorAll(".view-maps .su-card__contents h3 a");
  if(categories.length > 0){
    categories.forEach(category => {
      category.addEventListener("click", contentUpdate);
    });
  }

  function contentUpdate(e) {
    horTabs.forEach(horTab => {
      horTab.classList.remove("selected");
      if(horTab.innerHTML.match(/href="([^"]*)/)[1] === e.target.hash){
        horTab.classList.add("selected");
      }
    });
    horTabPanes.forEach(horTabPane => {
      if(!horTabPane.classList.contains("horizontal-tab-hidden")){
        horTabPane.classList.add("horizontal-tab-hidden");
      }
      if ('#'+ horTabPane.id === e.target.hash){
        horTabPane.classList.remove("horizontal-tab-hidden");
      }
    });
  }
});



//
// Add button back to top
//
let windowHeight = window.innerHeight;
window.addEventListener("scroll", (ev) => {
    if (window.pageYOffset > windowHeight) {
        let backtop = document.createElement('button');
        backtop.classList.add("backtop");
        backtop.textContent = 'Back to the Top';
        if (!document.querySelector(".backtop")) {
            document.body.appendChild(backtop);
            document.querySelector(".backtop").addEventListener("click", (ev) => {
                scrollToTop(500);
            });
        }
    } else {
        if (document.querySelector(".backtop")) {
            document.querySelector(".backtop").remove();
        }
    }
});


//Scroll Top Animation
//Credit https://stackoverflow.com/questions/21474678/scrolltop-animation-without-jquery

function scrollToTop(duration) {
    // cancel if already on top
    if (document.scrollingElement.scrollTop === 0) return;

    const cosParameter = document.scrollingElement.scrollTop / 2;
    let scrollCount = 0,
        oldTimestamp = null;

    function step(newTimestamp) {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollCount will be Infinity
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
            if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
            document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}

function alignCenter(ban,txt){
  txt.style.bottom = ban.offsetHeight/2 - txt.offsetHeight/2 + "px";
}


